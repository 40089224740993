import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Header from '../common/header/Header';
import { IStore } from '../../store';
import DASHBOARD_IMAGE from '../../assets/images/dashboard.svg';



const MarketReportLanding = (): JSX.Element => {
  const showPrivacyAndTermsModal = useSelector(
    (store: IStore) => store.common.showPrivacyAndTermsModal
  );
  const userData = useSelector((store: IStore) => store.userProfile?.userData);
  const displayName = userData?.displayName.split(' ')?.[0];

  return (
    <div>
      <Header />
      <StyleAppStoreComponent>

          <label className='welcome-text top-text'>Welcome, {displayName}</label>
          <div className='text top-text'>Let's view, download or query on Market Reports on different sectors</div>
          <div className='dashboard'>
            <div className='dashboard-left'>
              <div className='left-text'>Market Reports</div>
              <div className='bold-text'>Explore Comprehensive <br /> Market Insights</div>
              <div className='text text-bottom'>Access, download, or analyze reports from diverse sectors to stay ahead of market trends</div>
              <button className='launch-button'>Launch</button>
            </div>
            <div className='dashboard-right'>
              <img src={DASHBOARD_IMAGE} alt='dashboard' />
            </div>
        </div>
      </StyleAppStoreComponent>
    </div>
  );
};

const StyleAppStoreComponent = styled.div`
  background: ##1A1A24;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
   margin-top: 24px;
    margin-left: 12px;
    margin-bottom: 24px;
    width: 98%;

  .welcome-text {
    font-size: 28px;
    font-family: EYInterstate;
    font-weight: 400;
    line-height: 36px;
    color: #DEDEE2;
  }
  .text {
    font-size: 16px;
    font-family: EYInterstate;
    font-weight: 300;
    line-height: 24px;
    color: #A4A3B1;
    margin-top: 8px;
  }

 .dashboard {
  width: 98%;
  max-width: 1900px;
  height: auto;
  min-height: 424px;
  border-radius: 6px;
  border: 1px solid #3A3A4A;
  display: flex;
  justify-content: space-between;
  background: #23232F;
  margin-top: 24px;
  margin-bottom: 200px;
  padding: 65px;
}
  .dashboard-left {
    width: 70%;
  }
  
  .dashboard-right {
    width: 30%;
  }

  .left-text {
    font-size: 14px;
    font-family: EYInterstate;
    font-weight: 300;
    line-height: 20px;
    color: #FF9831;
  }
  
  .bold-text {
    font-size: 46px;
    line-height: 60px;
    font-family: EYInterstate;
    font-weight: 400;
  }
    .text-bottom{
      margin: 24px 0 30px 0;
    }

    .launch-button{
      height: 48px;
      width: 158px;
      background: rgb(255, 255, 255);
      color: rgb(26, 26, 36);
      border-radius: 6px;
      outline: none;
      border: 1px solid #fff;
      margin-top: 10px;
    }

    .top-text {
      width: 100%;
      position: relative;
      left: 1%;
    }
`;

export default MarketReportLanding;