import { apiRoutes, get, post, getAuthToken, getTeamsAuthToken } from './api.service';
import chatApiRoutes from './api';
import { type IUserRolesResponse } from '../models/IUserRoles';
import errorStatus from '../utils/ErrorStatus';
import { LOCAL_STORAGE, RESPONSE_STATUS_CODE } from '../app.constant';

export const signUserOut = async (): Promise<any> => {
  return await post(apiRoutes.signOut, null);
};

const handleNodataError = (error: any): any => {
  if (
    error?.response?.status === 404 ||
    error?.response?.data?.errors?.[0]?.message === 'Data Not Found.'
  ) {
    return null;
  }
};

const fomatted = (response: any): any => {
  return response.reduce(
    (a: any, c: any) =>
      c?.status === 'fulfilled'
        ? { ...a, ...(typeof c?.value === 'string' ? { image: c.value } : c.value) }
        : { ...a },
    {}
  );
};

export const getProfilePicture = async (): Promise<any> => {
  try {
    return await get(apiRoutes.profilePicture);
  } catch (error) {
    return handleNodataError(error);
  }
};

export const getUserProfile = async (): Promise<any> => {
  let response: any;
  response = await Promise.allSettled([await getAuthToken(), await getProfilePicture()]);
  localStorage.setItem(LOCAL_STORAGE.USER_PROFILE_PIC, response[1]?.value ? response[1]?.value : '');
  return fomatted(response);
};

export const fetchUserRoles = async (): Promise<IUserRolesResponse | any> => {
  const data = await get(apiRoutes.userRoles);
  return data.tenantRoles;
};


export const customErrorStatus = (message: any): any => {
  switch (message) {
    case 'Network Error':
      return errorStatus(RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR);
    default:
      return errorStatus(RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR);
  }
};
