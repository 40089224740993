import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import sassVars from '../../../styles/variables.module.scss';
import AvatarInitials from '../../common/AvatarInitials';
import { HELP_URL } from '../../../app.constant';
import ProfileMenu from '../profileMenu/ProfileMenu';
import LogoutModal from '../logout/LogoutModal';
import { useDispatch } from 'react-redux';
import { fetchUserProfile } from '../../../store/actions/userProfileActions';
import { closeIcon } from '../../../commonImage';
import FeedbackModal from '../feedbackModal/FeedbackModal';
const { grey3A, fontInterstate, lightBlue } = sassVars;
interface IOwnProps {
  userData: {
    profileImage: string;
    displayName: string;
    sid?: { preferredName: string };
    shortName?: string;
  };
  profileMenuList?: Array<{ id: number; item: string; icon: string }>;
  disableHelpMenu: boolean;
  isValidUser?: boolean;
}

const ProfileCard: React.FC<IOwnProps> = ({
  userData,
  profileMenuList = [],
  disableHelpMenu = false,
  isValidUser = true
}: IOwnProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  const dropdownRef = useRef<HTMLElement | null>(null);
  const menuTriggerRef = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const handleProfileMenu = (e: any): void => {
    e.stopPropagation();
    setIsActive(!isActive);
  };
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const configureMenuList = disableHelpMenu
    ? profileMenuList.filter((x) => x.item !== 'Help')
    : profileMenuList;

  const onClickMenuIcon = (e: any, item: any): void => {
    e.stopPropagation();
    setIsActive(!isActive);
    switch (item) {
      case 'Share Feedback':
        openFeedbackModal();
        break;
      case 'Support':
        window.open(HELP_URL, '_blank');
        break;
      case 'Contact us':
        window.location.href = 'mailto:competitiveedge@uk.ey.com';
        break;
      case 'Logout':
         setShowLogoutModal(true);
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        menuTriggerRef.current &&
        !menuTriggerRef.current.contains(e.target)
      ) {
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const openFeedbackModal = () => {
    setShowFeedbackModal(true);
  }
  return (
    <ProfileStyled>
      <div className='ie-header-icons-placeholder'>
        <div className='ie-row'>
          <div className='ie-col-xs-1'>
            <div className='ie-dropdown'>
              <div className='ie-dropdown-trigger' ref={menuTriggerRef} onClick={handleProfileMenu}>
                <button className='ie-icon-button'>
                  <div className='ie-avatar ie-avatar-size-small '>
                    {userData.profileImage ? (
                      <img
                        className='image-round ie-avatar ie-avatar-size-small'
                        alt='Profile'
                        src={userData.profileImage}
                      />
                    ) : (
                      userData.displayName?.length > 0 && (
                        <AvatarInitials title={userData.displayName} />
                      )
                    )}
                  </div>
                </button>
              </div>
              {isActive && (
                <ProfileMenu
                  dropdownRef={dropdownRef}
                  isActive={isActive}
                  disableHelpMenu={disableHelpMenu}
                  profileMenuList={configureMenuList}
                  userData={userData}
                  onClickMenu={onClickMenuIcon}
                />
              )}
              <LogoutModal
                headerIcon={
                  <img
                    onClick={() => {
                      setShowLogoutModal(false);
                    }}
                    className='se-close'
                    src={closeIcon}
                    alt='close-icon'
                  />
                }
                show={showLogoutModal}
                onClose={() => {
                  setShowLogoutModal(false);
                }}
              />
              <FeedbackModal
                headerIcon={
                  <img
                    onClick={() => {
                      setShowFeedbackModal(false);
                    }}
                    className='se-close'
                    src={closeIcon}
                    alt='close-icon'
                  />
                }
                show={showFeedbackModal}
                onClose={() => {
                  setShowFeedbackModal(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};
export default ProfileCard;

const ProfileStyled = styled.div`
  .ie-header-icons-placeholder {
    position: absolute;
    right: -0.1rem;
    top: 5px;
    display: flex;
    margin-left: 0.42857rem;
     & .ie-row {
      height: 100%;
      flex-basis: 100%;
    }
    & .ie-dropdown {
      position: relative;
      display: inline-block;
      & .top:before {
        content: "";
        border-bottom: 12px solid ${grey3A};
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        position: absolute;
        right: 26px;
        top: -12px;
      }

  
    & .ie-dropdown-trigger {
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      border: 1px solid transparent;
      position: relative;
      z-index: 1;
      & button {
        margin-right: 0.42857rem;
        display: flex;
        align-items: center;
        width: 3.85714rem;
        justify-content: center;
      }
    }
    & .ie-icon-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      padding: 0;
    }
    & .ie-avatar-size-small {
      height: 2.71429rem;
      width: 2.71429rem;
    }
    & .ie-avatar {
      border-style: solid;
      border: 0.14286rem solid transparent;
      position: relative;
      border-radius: 50%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 2px;
      & .image-round {
        border-radius: 50%;
      }
     & .center-wrapper-avatar {

    line-height: 42px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: ${lightBlue};
    font-family: ${fontInterstate};
    font-size: 1rem;
    position: relative;
    top: 19px;
        }
       }
    @media screen and (min-width: 64em) {
      .ie-row {
        margin-right: -12px;
        margin-left: -12px;
      }
    }
    @media screen and (min-width: 48em) {
      .ie-row {
        margin-right: -12px;
        margin-left: -12px;
      }
    }
    @media screen and (min-width: 36em) {
      .ie-row {
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }  
`;
