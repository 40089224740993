import React, { useEffect, useState } from 'react';
import fetchIntercept from 'fetch-intercept';
import { Guid as guid } from 'guid-typescript';
import { useSelector } from 'react-redux';
import { IStore } from './store';
import Footer from './components/common/footer/Footer';
import { getCurrentSessionId } from './services/session.service';
import { X_TENANT_ID } from './app.constant';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import PolicyStepper from './components/common/policy/PolicyStepper';
import UnAuthorizedAccess from './components/unAuthorizedAccess/UnAuthorizedAccess';
import { useNavigate } from 'react-router-dom';
import { DigitalData } from './utils/adobeAnalytics/models/digitalDataModel';
import { digitaldata } from './utils/adobeAnalytics/config';
import { executeAnalytics, getLastUri } from './utils/adobeAnalytics/adobeAnalyticsHelperFn';
import MarketReportLanding from './components/marketReportLanding/MarketReportLanding';


declare global { 
  interface Window { 
    digitaldata: DigitalData; 
  } 
} 

if (!window.digitaldata) { 
  window.digitaldata = digitaldata; 
} 
let userId: string;
function App(): JSX.Element {
  const showPrivacyAndTermsModal = useSelector(
    (store: IStore) => store.common.showPrivacyAndTermsModal
  );


  const showUnauthorizedAccess = useSelector(
    (store: IStore) => store.common.showUnauthorizedAccess
  );

  useEffect(() => {
    let prevPageLastUri = getLastUri(window.location.href);
    executeAnalytics({ page: { pageName: prevPageLastUri } });
    const timer = setInterval(() => {
      const lastUri = getLastUri(window.location.href);
      if (lastUri != prevPageLastUri) {
        executeAnalytics({ page: { pageName: lastUri } });
        prevPageLastUri = lastUri;
      }
    }, 500);
    return () => clearInterval(timer);
  }, []);

  const sessionId = getCurrentSessionId();

  fetchIntercept.register({
    request: function (url: any, config: { headers: any }) {
      if (!config.headers) {
        config.headers = {};
      }
      config!.headers['Request-Id'] = guid.create();
      config!.headers['X-Correlation-Id'] = guid.create();
      config!.headers['X-Window-Id'] = sessionId;
      config!.headers['X-Tenant-Id'] = X_TENANT_ID;
      config!.headers['session-id'] = sessionId;
      return [url, config];
    },

    requestError: function (error: any) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response: any) {
      // Modify the reponse object
      return response;
    },

    responseError: function (error: any) {
      // Handle an fetch error
      return Promise.reject(error);
    }
  });

  const navigate = useNavigate();

  useEffect(() => {
    const navigationEntry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
      if (navigationEntry && navigationEntry.type === 'reload') {
      setTimeout(() => {
        navigate('/');
      }, 10);
    }
  }, []);

  return (
    <div className='App'>
      <>
        {!showPrivacyAndTermsModal && (
         showUnauthorizedAccess ? (
            <UnAuthorizedAccess />
          ) : (
            <ErrorBoundary>
              <MarketReportLanding />
            </ErrorBoundary>
          )
        )}
        {showPrivacyAndTermsModal && (
          <div className="page-layout se-container">
            <PolicyStepper />
          </div>
        )}
      </>
       <Footer />
    </div>
  );

}

export default App;
