import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './styles/index.scss';
import { BACKEND_API_URL, CLIENT_ID, TENANT } from './app.constant';
import { type IAppConfig } from './models/IAppConfig';
import { initializeSession } from './services/session.service';
import { initConfig } from './AppConfig';
import { initApi } from './services/api.service';
import configureMsalAuthProvider from './services/msalConfig';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import LoadIndicator from './components/common/loadIndicator/LoadIndicator';
import { sha256 } from 'js-sha256';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const Root: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

window.addEventListener('DOMContentLoaded', () => {
  const settingsEl = document.getElementById('settings');
  const appConfig: IAppConfig = {
    activeDirectory: {
      tenant: settingsEl === null ? TENANT : settingsEl.getAttribute('data-tenant')!,
      clientId: settingsEl === null ? CLIENT_ID : settingsEl.getAttribute('data-client-id')!
    },
    baseApiUrl: BACKEND_API_URL,
    azureMapsClientId: ''
  };

  initializeSession();
  initConfig(appConfig);
  initApi(appConfig);
  localStorage.setItem('acceptPolicy', 'false');
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get('q');
  const searchObj = param ? JSON.parse(param) : '';

  const msalAuthFlow = () => {
    void configureMsalAuthProvider(appConfig.activeDirectory, searchObj).then((result) => {
      root.render(
        // @ts-expect-error - AzureAD typings are not up to date
        <AzureAD provider={result}>
          {({ login, authenticationState, accountInfo }: any): JSX.Element => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                localStorage.setItem('accountName', accountInfo.account.name);
                localStorage.setItem('accountEmail', accountInfo.account.userName);
                window.digitaldata.user.userId = sha256(accountInfo.account.userName.toLowerCase());
                return <Root />;

              case AuthenticationState.Unauthenticated:
                login();
                return <></>;

              case AuthenticationState.InProgress:
                return (
                  <div className='global-loder'>
                    <div className='center-wrapper'>
                      <LoadIndicator />
                    </div>
                  </div>
                );

              default:
                return <Root />;
            }
          }}
        </AzureAD>
      );
    });
  };


    msalAuthFlow();
});
