import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PolicyContainer from "../policy/PolicyContainer";
import scssVar from "../../../styles/variables.module.scss";
import { useDispatch } from "react-redux";
import { showPrivacyAndTermsModal } from "../../../store/actions/commonActions";
import { acceptPrivacyPolicy,} from "../../../services/privacyPolicy.service";
import { useNavigate, useLocation } from 'react-router-dom';


export const toggleSticky = (criteria: boolean, setIsStickyApplied: React.Dispatch<React.SetStateAction<boolean>>, isStickyApplied: boolean): void => {
  if (criteria) {
    if (!isStickyApplied) {
      setIsStickyApplied(true);
    }
  } else {
    setIsStickyApplied(false);
  }
};
const PolicyStepper = () => {
  const [openModal, setOpenModal] = useState<"Privacy" | null>(
    "Privacy"
  );
  const [actionBtnEnabled, setActionButtonEnabled] = useState<true | false>(
    false
  );
  const [loading, setLoading] =useState(false)
  const history = useNavigate();
  const dispatch= useDispatch()
  const [isStickyApplied, setIsStickyApplied] = useState<boolean>(false);

  const listenToScroll = () => {
      let header = document.getElementById("compare-page");
      let offset = header?.getBoundingClientRect();
      let sticky = offset?.top ? offset?.top : 0;
      toggleSticky(window.pageYOffset > sticky, setIsStickyApplied, isStickyApplied);
  };

  useEffect(() => {
      window.addEventListener('scroll', listenToScroll);
      return () => window.removeEventListener('scroll', listenToScroll);
  }, [])
  //Handle I agree statement checkbox
  const hanldeCheck = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setActionButtonEnabled(e.target.checked);
  };
 const handleActionButton =()=>{
  //  let modalType:"Privacy" | "Terms" | null= openModal==="Privacy"? "Terms": 'Terms';
  let modalType:"Privacy"  =  "Privacy" ;

  setOpenModal(modalType);
    setActionButtonEnabled(false);
     window.scrollTo(0, 0) ;
     openModal==="Privacy" && acceptPrivacy();
    }

 const acceptPrivacy =async()=>{
   setLoading(true)
   let hasAccepted = await acceptPrivacyPolicy();
   if(hasAccepted.hasAccepted){
    dispatch(showPrivacyAndTermsModal(false, [])); 
    window.location.reload();
    history('/')
   }
   }
  //  const tracker = openModal === "Client" ? 'step-tracker-line-demo complete-state' : (openModal === "Terms" ? "step-tracker-line-term complete-state" : 'step-tracker-line-term')
  return (
    <StyleFooter>
      <div className="privacy-main">
        <div className="privacy-policy-conatiner">
          <div  className={`privacy-header ${isStickyApplied ? 'sticky' : ''}`}>
            {/* <div className="step-tracker">
              <div
                className={`${tracker}`}
              ></div>
              {
                openModal !== "Client"  && <div className="step-tracker-line-demo"></div>
              }
              <div className="privacy-notice-icon">
                <div className="inprogress-state">
                  <div className="icon-wrapper complete-state">
                    <img
                      className="app-icon"
                      alt="Privacy notice"
                      src={lockIcon}
                    />
                  </div>
                </div>
                <span className="icon-label">Privacy Notice</span>
              </div>
              <div className="terms-conditions-icon">
                <div
                  className={`inprogress-state validate-icon ${(openModal !==
                    "Terms" && openModal !==
                    "Client") && "no-progress-state"}`}
                >
                  <div
                    className={`icon-wrapper ${(openModal === "Terms" || openModal === "Client") && 
                      "complete-state"}`}
                  >
                    <img
                      className="app-icon"
                      alt="Terms of use"
                      src={termsOfUseIcon}
                    />
                  </div>
                </div>
                <span className="icon-label">Terms of use</span>
              </div>
               <div className="client-demo-icon">
                <div
                  className={`inprogress-state validate-icon demoPosition ${openModal !==
                    "Client" && "no-progress-state"}`}
                >
                  <div
                    className={`icon-wrapper ${openModal === "Client" &&
                      "complete-state"}`}
                  >
                    <span className="demo-icon">!</span>
                  </div>
                </div>
                <span className="icon-label">Client demo disclaimer</span>
              </div> 
            </div> */}
          </div>
          <PolicyContainer
            type={openModal}
            actionBtnEnabled={actionBtnEnabled}
            onChangeCheckBox={hanldeCheck}
            onClose={(): void => setOpenModal(null)}
            onClickContinue={handleActionButton}
            isLoading={loading}
          />
        </div>
      </div>
    </StyleFooter>
  );
};

const StyleFooter = styled.div`
  .privacy-main {
    height: 100%;
    margin-top:-13px;
    display: flex;
    flex-direction: column;
    display: flex !important;
    background-color: ${scssVar.darkblack} !important;
    color: ${scssVar.alternategray}!important;
    align-items: center;

    & .privacy-policy-conatiner {
      width: 85%;
      height: 75%;
      max-width: 1400px;
  }
    & .privacy-header {
      background-color:${scssVar.darkblack};
      padding: 46px 182px 35px 182px;
      &.sticky {
        position: fixed;
        width: calc(100% - 247px);
        z-index: 1;
        top: 44px;
    }
    }
    & .step-tracker {
      display: flex;
      flex-direction: row;
      position: relative;
    }
    & .step-tracker-line-term {
      height: 2px;
      width: 100%;
      background:${scssVar.gray};
    }
    & .step-tracker-line-demo {
      height: 2px;
      width: auto;
      background:${scssVar.gray};
    }
    & .privacy-notice-icon {
      position: absolute;
      width: 70px;
      left: -2px;
    }
    & .inprogress-state {
      background-color ${scssVar.yellow}!important;
      width: 36px;
      height: 36px;
      position: absolute;
      border-radius: 50%;
      top: -22px;
      left: -2px;
    }
    & .complete-state {
      background-color: ${scssVar.yellow}!important;
    }
    & .icon-wrapper {
      position: absolute;
      background: ${scssVar.gray};
      height: 28px;
      width: 28px;
      border-radius: 50%;
      top: 4px;
      left: 4px;

      & .app-icon {
        width: 22px;
        height: 22px;
        font-size: 1.5rem;
        display: inline-block;
        position: relative;
        left: 3px;
        top: 3px;
        margin-bottom: 8px;
      }
    }
    & .icon-label {
      position: absolute;
      left: -18px;
      top: 35px;
      color:${scssVar.lightgray};
      font-family: ${scssVar.fontInterstate};
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      width: 100px;
  }
  & .validate-icon {
    left: 0px;
  }
  & .no-progress-state {
    background-color: ${scssVar.gray} !important;
   }
   & .privacy-content {
    box-shadow: rgb(0 0 0 / 70%) 0px 0px 30px 0px;
    border: 0px;
    padding: 0px;
    align-items: center;
    text-align: center;
    width: 800px;
    margin: auto;
    margin-top: 84px;
     }
    }
   & .terms-conditions-icon {
      position: absolute;
      width: 3%;
      right: -2px;
     & .icon-label {
      width: 100px;
    }
   }
   & .client-demo-icon {
    position: absolute;
    width: 70px;
    right: -2px;
   & .icon-label {
    width: 150px;
  }
  & .demoPosition {
    left:34px;
  }
  & .demo-icon {
    position: absolute;
    width: 5px;
    height: 19px;
    left: 9px;
    top: -2px;
    font-family: 'Alatsi';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #2E2E38;
  }
 }
    & .text-right {
      text-align: right;
    }
 }
`;

export default PolicyStepper;
