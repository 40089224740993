import { type AnyAction } from 'redux';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const ADD_LOADING_INDICATOR = 'ADD_LOADING_INDICATOR';
export const REMOVE_LOADING_INDICATOR = 'REMOVE_LOADING_INDICATOR';
export const SHOW_PRIVACY_AND_TERMS_MODAL = 'SHOW_PRIVACY_AND_TERMS_MODAL';
export const SHOW_UNAUTHORIZED_ACCESS = 'SHOW_UNAUTHORIZED_ACCESS';

export function setUSerEmail(email: string): AnyAction {
  return {
    type: SET_USER_EMAIL,
    email
  };
}

export function addLoadingIndicator(name: string): AnyAction {
  return {
    type: ADD_LOADING_INDICATOR,
    value: name
  };
}

export function removeLoadingIndicator(name: string): AnyAction {
  return {
    type: REMOVE_LOADING_INDICATOR,
    value: name
  };
}

export function showPrivacyAndTermsModal(value: boolean, policyTypeList:any): AnyAction {
  return {
    type: SHOW_PRIVACY_AND_TERMS_MODAL,
    data: value,
    policyTypeList
  };
}

export function showUnauthorizedAccess(value: boolean): AnyAction {
  return {
    type: 'SHOW_UNAUTHORIZED_ACCESS',
    value
  };
}

